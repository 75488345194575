import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { HeroSectionColors, LayoutOptions } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import { LocaleTag } from '../../../../config/locales';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../components/Icon/Icon';
import CommonData from '../../../data/common/common';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import './style.scss';

type Props = PageProps & WithTranslation;

const BrokerPortal: React.FC<Props> = (props) => {
  const { t, location } = props;
  const { activeLocaleTag, updateLocale } = useLocaleContext();

  useEffect(() => {
    const hasLocaleInPath = location.pathname.match(/\/en\/|\/fr\//);

    if (hasLocaleInPath) {
      const shouldUpdateLocale = !hasLocaleInPath[0].includes(activeLocaleTag);

      if (shouldUpdateLocale) {
        updateLocale(activeLocaleTag === LocaleTag.EN ? LocaleTag.FR : LocaleTag.EN);
      }
    }
  }, []);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('brokerPortal.seo.title'),
    },
    page: {
      title: t('brokerPortal.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          return <li key={`${str}-${i}`}>{str}</li>;
        })}
      </ul>
    );
  };

  const brokerEmail = t(`${LocaleNameSpaceKeys.COMMON}:emails.brokerSupport`);
  const brokerPhone = CommonData.tollFreeCustomerServiceNumber.brokerRelated;

  return (
    <Layout options={layoutOptions} className="BrokerPortal">
      <div className="grid-container">
        <div className="row row-wrap BrokerPortal__generic-gap">
          <section className="column column-60">
            <div className="BrokerPortal__generic-gap">
              <p>
                <Trans t={t} i18nKey="brokerPortal.introBlock.body" />
              </p>
              <p>{t('brokerPortal.introBlock.listTitle')}</p>
              {generateList(t('brokerPortal.introBlock.list', { returnObjects: true }))}
            </div>

            <div className="row row-wrap BrokerPortal__generic-gap">
              {/* <h4>{t('brokerPortal.troubleLoggingInBlock.heading')}</h4> */}
              <p>
                <Trans
                  i18nKey="brokerPortal.troubleLoggingInBlock.body"
                  t={t}
                  values={{ brokerEmail, brokerPhone }}
                >
                  <ExternalLink
                    href={`mailto:${brokerEmail}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${brokerEmail}`}
                  />
                  <ExternalLink
                    href={`tel:${brokerPhone}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${brokerPhone}`}
                  />
                </Trans>
              </p>
            </div>
          </section>
          <aside className="column column-33">
            <ButtonLink
              className="BrokerPortal__login-button"
              to={CommonData.websites.brokerPortal[activeLocaleTag]}
              linkOptions={{ isExternalLink: true }}
              iconOptions={{
                icon: IconTypes.LOCK,
              }}
              styleOptions={{
                invertColors: true,
                isInline: true,
              }}
            >
              {t('brokerPortal.loginButtonLabel')}
            </ButtonLink>
            <div>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{
                  isInline: true,
                }}
              >
                {t(`${LocaleNameSpaceKeys.COMMON}:pageTitles.meetTheTeam`)}
              </ButtonLink>
            </div>
            <ButtonLink
              className="BrokerPortal__faq-button"
              to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL.FAQ)}
              iconOptions={{
                icon: IconTypes.ARROW_NEXT,
              }}
            >
              {t('faq.heading')}
            </ButtonLink>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(BrokerPortal);
